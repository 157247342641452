// Generated by Framer (f712822)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const serializationHash = "framer-U6WC4"

const variantClassNames = {dhJ1hKHs0: "framer-v-42qa5b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, KJIHSCPqE: click ?? props.KJIHSCPqE} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, KJIHSCPqE, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "dhJ1hKHs0", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1cms42a = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (KJIHSCPqE) {const res = await KJIHSCPqE(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-42qa5b", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dhJ1hKHs0"} onTap={onTap1cms42a} ref={refBinding} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, ...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-16g7n4o-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"q0aZzeItJ-container"} nodeId={"q0aZzeItJ"} rendersWithMotion scopeId={"LmV8QwlWR"}><Phosphor color={"var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11))"} height={"100%"} iconSearch={"House"} iconSelection={"Plus"} id={"q0aZzeItJ"} layoutId={"q0aZzeItJ"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-U6WC4.framer-1wzlnu6, .framer-U6WC4 .framer-1wzlnu6 { display: block; }", ".framer-U6WC4.framer-42qa5b { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 14px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-U6WC4 .framer-16g7n4o-container { flex: none; height: 30px; position: relative; width: 30px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-U6WC4.framer-42qa5b { gap: 0px; } .framer-U6WC4.framer-42qa5b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-U6WC4.framer-42qa5b > :first-child { margin-left: 0px; } .framer-U6WC4.framer-42qa5b > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 58
 * @framerIntrinsicWidth 58
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"KJIHSCPqE":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerLmV8QwlWR: React.ComponentType<Props> = withCSS(Component, css, "framer-U6WC4") as typeof Component;
export default FramerLmV8QwlWR;

FramerLmV8QwlWR.displayName = "Plus";

FramerLmV8QwlWR.defaultProps = {height: 58, width: 58};

addPropertyControls(FramerLmV8QwlWR, {KJIHSCPqE: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerLmV8QwlWR, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})